<template>
  <v-container>
    <v-row>
      <v-col>
        <div v-if="loading" class="text-center">
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>

        <v-form v-if="!loading && !loadingError" ref="form">
          <v-stepper v-model="step">
            <v-stepper-header>
              <v-stepper-step step="1" :complete="step > 1"
                >Company information</v-stepper-step
              >
              <v-divider></v-divider>
              <v-stepper-step step="2" :complete="step > 2"
                >ACH Information</v-stepper-step
              >
              <v-divider></v-divider>
              <v-stepper-step step="3" :complete="step > 3"
                >Authorized contact information</v-stepper-step
              >
              <v-divider></v-divider>
              <v-stepper-step step="4" :complete="step > 4"
                >Theaters &amp; distribution
              </v-stepper-step>

              <v-divider></v-divider>
              <v-stepper-step step="5" :complete="step > 5"
                >Review contract
              </v-stepper-step>

              <v-divider></v-divider>
              <v-stepper-step step="6" :complete="step == 6"
                >Summary
              </v-stepper-step>
            </v-stepper-header>
            <!-- company info -->
            <v-stepper-items>
              <v-stepper-content step="1">
                <v-row class="mt-4 mb-4">
                  <v-col>
                    <p>
                      Please provide your company’s legal name and address to be
                      used with {{ getContractYear() }} program contract
                    </p>
                  </v-col>
                </v-row>

                <v-text-field
                  label="Company name"
                  v-model="registration.payee.payee_name"
                  required
                  :loading="loading"
                  :rules="payeeNameRules"
                  spellcheck="false"
                ></v-text-field>

                <v-text-field
                  label="DBA"
                  v-model="registration.payee.dba"
                  :loading="loading"
                  spellcheck="false"
                ></v-text-field>

                <v-row>
                  <v-col cols="2">
                    <v-text-field
                      label="House number"
                      v-model="registration.payee.house_number"
                      required
                      :loading="loading"
                      :rules="payeeStreetRules"
                      spellcheck="false"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      label="Street"
                      v-model="registration.payee.street"
                      required
                      :loading="loading"
                      :rules="payeeStreetRules"
                      spellcheck="false"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      label="Street 2"
                      v-model="registration.payee.street_2"
                      required
                      :loading="loading"
                      spellcheck="false"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="mb-4">
                  <v-col>
                    <v-text-field
                      label="City"
                      v-model="registration.payee.city"
                      required
                      :loading="loading"
                      :rules="payeeCityRules"
                      spellcheck="false"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-select
                      v-model="registration.payee.state"
                      :items="states"
                      label="State"
                      required
                      :loading="loading"
                      :rules="stateRules"
                    ></v-select>
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="Zip"
                      v-model="registration.payee.zip"
                      required
                      :loading="loading"
                      :rules="payeeZipRules"
                      spellcheck="false"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-btn
                  :loading="loading"
                  color="primary"
                  :disabled="!stepOneIsValid()"
                  @click.native="step = 2"
                  >Continue</v-btn
                >
              </v-stepper-content>

              <!-- ACH info -->
              <v-stepper-content step="2">
                <v-row class="mt-4 mb-4">
                  <v-col>
                    <p>
                      OPTIONAL INFO: All {{ getContractYear() }} rebates will be
                      paid via ACH. You can provide the information now, if
                      available or it will be requested prior to quarterly
                      payments.
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      label="Tax ID"
                      v-model="registration.payee.tax_id"
                      :loading="loading"
                      spellcheck="false"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="Bank name"
                      v-model="registration.payee.bank_name"
                      :loading="loading"
                      spellcheck="false"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="Bank routing number"
                      v-model="registration.payee.bank_routing_number"
                      :loading="loading"
                      :rules="bankRoutingRules"
                      spellcheck="false"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="Bank account number"
                      v-model="registration.payee.bank_account_number"
                      :loading="loading"
                      spellcheck="false"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mb-4">
                  <v-col cols="5">
                    <v-text-field
                      label="Account holder name"
                      v-model="registration.payee.bank_account_holder_name"
                      :loading="loading"
                      spellcheck="false"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-btn text @click.native="step = 1">Previous</v-btn>
                <v-btn
                  :loading="loading"
                  color="primary"
                  :disabled="!stepTwoIsValid()"
                  @click.native="step = 3"
                  >Continue</v-btn
                >
              </v-stepper-content>

              <!-- user -->
              <v-stepper-content step="3">
                <v-row class="mt-4 mb-4">
                  <v-col>
                    <p>
                      Please provide the name and contact information of the
                      authorized business representative who will be signing
                      this agreement.
                    </p>
                  </v-col>
                </v-row>
                <v-text-field
                  label="Full name"
                  v-model="employee.name"
                  required
                  :rules="employeeNameRules"
                  spellcheck="false"
                  hint="Signee's name"
                  :persistent-hint="true"
                ></v-text-field>
                <v-text-field
                  label="Email"
                  v-model="employee.email"
                  required
                  :rules="employeeEmailRules"
                  spellcheck="false"
                ></v-text-field>

                <v-text-field
                  label="Phone"
                  v-model="employee.phone"
                  required
                  :rules="employeePhoneRules"
                  hint="(###) ###-####"
                  class="mb-4"
                  spellcheck="false"
                ></v-text-field>

                <v-btn text @click.native="step = 2">Previous</v-btn>
                <v-btn
                  :disabled="!stepThreeIsValid()"
                  color="primary"
                  @click.native="step = 4"
                  >Continue</v-btn
                >
              </v-stepper-content>

              <!-- Distributors -->
              <v-stepper-content step="4">
                <v-row>
                  <v-col cols="12">
                    <v-toolbar>
                      <v-toolbar-title class="pr-8"
                        >List of distributors and theaters</v-toolbar-title
                      >

                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            :loading="loading"
                            :disabled="loading"
                            color="primary darken-1"
                            text
                            @click="addTheater"
                            icon
                            v-on="on"
                          >
                            <v-icon>mdi-home-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>Add distributor/theater</span>
                      </v-tooltip>

                      <v-spacer></v-spacer>
                      <v-spacer></v-spacer>
                      <v-spacer></v-spacer>

                      <v-text-field
                        class="pl-10"
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                        spellcheck="false"
                      ></v-text-field>

                      <v-progress-linear
                        :active="loading"
                        :indeterminate="true"
                        absolute
                        bottom
                        color="blue-grey darken-3"
                      ></v-progress-linear>
                    </v-toolbar>

                    <v-card>
                      <v-data-table
                        :headers="headers"
                        :items="registration.warehouses"
                        item-key="subId"
                        :search="search"
                        hide-default-footer
                        disable-pagination
                        :item-class="getRowClass"
                        single-select
                        @dblclick:row="dblclickRow"
                        dense
                      >
                        <template v-slot:item.distributor_name="{ item }">
                          {{ getDistributorName(item) }}
                        </template>

                        <template v-slot:item.actions="{ item }">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                text
                                icon
                                v-on="on"
                                class="d-inline"
                                @click="editTheater(item)"
                              >
                                <v-icon d-inline>mdi-pencil</v-icon>
                              </v-btn>
                            </template>
                            <span>Edit</span>
                          </v-tooltip>

                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                text
                                icon
                                v-on="on"
                                @click="removeTheater(item)"
                              >
                                <v-icon>mdi-trash-can-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>Remove</span>
                          </v-tooltip>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <div class="errorMsg" v-if="!stepFourIsValid()">
                      {{ getStepFourErrorMsg() }}
                    </div>
                  </v-col>
                </v-row>

                <v-btn text @click.native="step = 3">Previous</v-btn>
                <v-btn
                  :loading="sendingContractInfo"
                  :disabled="!stepFourIsValid()"
                  color="primary"
                  @click.native="sendContractInfo()"
                  >Continue</v-btn
                >
              </v-stepper-content>

              <!-- Contract -->
              <v-stepper-content step="5">
                <iframe
                  id="frame"
                  ref="contractDoc"
                  :src="contractURL"
                  width="100%"
                  height="500px"
                >
                </iframe>

                <p>
                  I hereby attest that I am an authorized representative of the
                  business described and that the electronic signature included
                  in this agreement is intended to have the same force and
                  effect as a manual signature. Delivery of a copy of this
                  agreement bearing an electronic signature by electronic mail
                  in “portable document format” (“.pdf”) form will have the same
                  effect as physical delivery of the paper document bearing an
                  original or electronic signature. Confirmation of the delivery
                  of the agreement by electronic means will constitute final
                  acceptance of the agreement and attest the validity of the
                  electronic signature contained within the agreement.
                </p>

                <v-text-field
                  label="Signature"
                  v-model="employee.signature"
                  prefix="/s/"
                  required
                  :rules="signatureRules"
                  spellcheck="false"
                ></v-text-field>

                <div class="mb-5">{{ employee.name }}</div>

                <v-btn text @click.native="step = 4">Previous</v-btn>
                <v-btn
                  :disabled="!canSign()"
                  :loading="sendingContractInfo"
                  color="primary"
                  @click.native="signContract()"
                  >Sign</v-btn
                >
              </v-stepper-content>

              <!-- Summary -->

              <v-stepper-content step="6">
                <p>
                  Thank you for completing the online registration. A copy of
                  the completed agreement has been emailed to
                  {{ employee.email }}.
                </p>

                <p>
                  Please use the link in this email to confirm your agreement.
                  This program will not be active until your registration has
                  been confirmed.
                </p>

                <p>
                  Agreements received three (3) weeks after the start of a
                  quarter will not qualify for payment for that quarter.
                  Enrollment into the Program will begin the next quarter.
                </p>

                <p>
                  Shipments from reporting distributors will automatically be
                  tracked, however, you will be responsible for submitting
                  invoices from qualifying non-reporting distributors within one
                  (1) month after the end of each quarter for credit.
                </p>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-form>
      </v-col>
    </v-row>

    <!-- theater dialog -->
    <v-dialog v-model="theaterDialog" width="30%">
      <v-form ref="theaterForm">
        <v-card>
          <v-card-title>
            <span class="headline">Theater/distributor information</span>
          </v-card-title>
          <v-card-text>
            <v-select
              :items="distributorList"
              label="Distributor"
              v-model="currentTheater.distributor_name"
              :rules="distributorNameRules"
            ></v-select>

            <v-row>
              <v-col>
                <v-text-field
                  label="DCN"
                  v-model="currentTheater.external_customer_id"
                  required
                  :loading="loading"
                  :rules="dcnRules"
                  spellcheck="false"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  label="Theater name"
                  v-model="currentTheater.name"
                  required
                  :loading="loading"
                  :rules="theaterNameRules"
                  spellcheck="false"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="8">
                <v-text-field
                  label="City"
                  v-model="currentTheater.city"
                  required
                  :loading="loading"
                  :rules="cityRules"
                  spellcheck="false"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-select
                  v-model="currentTheater.state"
                  :items="states"
                  label="State"
                  required
                  :loading="loading"
                  :rules="stateRules"
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="theaterDialog = false">Cancel</v-btn>
            <v-btn
              color="green darken-3"
              text
              @click="saveTheaterDialog()"
              :disabled="!theaterValid()"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <confirm ref="confirm"></confirm>
  </v-container>
</template>

<script>
import api from "@/services/api";
import {
  hasErrors,
  isValidEmail,
  isValidPhone,
  deepEqual,
  stateAbbr
} from "@/lib/utils";
import confirm from "@/components/utils/Confirm";
import { mapState } from "vuex";

const VistarRegExp = /vistar/i;
const LibertyRegExp = /liberty/i;
const GoldMedalRegExp = /gold medal/i;

const defaultEmp = Object.freeze({
  name: null,
  email: null,
  phone: null,
  signature: null
});

const defaultTheater = Object.freeze({
  id: null,
  city: "",
  name: "",
  state: "",
  pipeline_id: null,
  distributor_name: "",
  external_customer_id: "",
  subID: null
});

const nameRegExp = /^\w+\s\w+/;

export default {
  components: {
    confirm
  },

  data() {
    return {
      // loading: false,
      loadingError: false,
      sendingContractInfo: false,
      regCode: "",
      step: 1,
      employee: Object.assign({}, defaultEmp),
      currentTheater: Object.assign({}, defaultTheater),
      theaterDialog: false,
      isEditing: false,
      loadFailed: false,
      contractURL: "",
      removedTheaters: [],
      addedTheaters: [],
      editedTheaters: [],
      headers: [
        { text: "Distributor name", sortable: true, value: "distributor_name" },
        {
          text: "DCN",
          sortable: true,
          value: "external_customer_id"
        },
        { text: "Theater name", sortable: true, value: "name" },
        { text: "City", sortable: true, value: "city" },
        { text: "State", sortable: true, value: "state" },
        { text: "Actions", sortable: true, value: "actions" }
      ],
      search: "",
      states: [],

      distributorList: [
        "Gold Medal Products Co.",
        "Indiana Concession Supply LLC.",
        "Mountain States Concessions",
        "Movie Foods LLC.",
        "Non-reporting distributor",
        "Prima Distribution Inc.",
        "Royal Paper Corporation",
        "VISTAR",
        "Western Concession Distribution LLC",
        "Y Hata & Company Limited",
      ],

      payeeNameRules: [v => !!v || "Company name is required"],
      payeeStreetRules: [v => !!v || "Street is required"],
      payeeCityRules: [v => !!v || "City is required"],
      stateRules: [
        v => !!v || "State is required",
        v => v?.length === 2 || "Invalid state",
        v => !!v?.trim() || "Invalid value"
      ],
      payeeZipRules: [
        v => !!v || "Zip is required",
        v => !!v?.trim() || "Invalid value"
      ],

      employeeNameRules: [
        v => !!v || "Name is required",
        v => this.validateEmployeeName(v)
      ],
      employeeEmailRules: [
        v => !!v || "Email is required",
        v => this.validateEmployeeEmail(v)
      ],
      employeePhoneRules: [
        v => !!v || "Phone is required",
        v => this.validateEmployeePhone(v)
      ],
      warehouseRules: [v => this.validateWarehouseRules(v)],
      distributorNameRules: [v => !!v || "Distributor name is required"],
      dcnRules: [v => this.ValidateDCN(v)],
      signatureRules: [
        v => !!v || "Signature is required",
        v => !!v?.trim() || "Invalid value",
        v =>
          v === this.employee.name ||
          "Signature must match name provided in Step 2"
      ],
      theaterNameRules: [
        v => !!v || "Name is required",
        v => !!v?.trim() || "Invalid value"
      ],
      cityRules: [
        v => !!v || "City is required",
        v => !!v?.trim() || "Invalid value"
      ],
      bankRoutingRules: [
        v => this.validateRoutingNumber(v) || "Invalid routing number"
      ]
    };
  },

  mounted() {
    this.regCode = this.$route.params.code;
    this.states = stateAbbr;
    this.$store.dispatch("registration/getRegistrationData", this.regCode);
  },

  methods: {
    stepOneIsValid() {
      if (this.loading) {
        return false;
      }

      const result =
        hasErrors(this.payeeNameRules, this.registration.payee.payee_name) ||
        hasErrors(this.payeeStreetRules, this.registration.payee.street) ||
        hasErrors(this.payeeCityRules, this.registration.payee.city) ||
        hasErrors(this.stateRules, this.registration.payee.state) ||
        hasErrors(this.payeeZipRules, this.registration.payee.zip);

      return !result;
    },

    stepTwoIsValid() {
      if (this.loading) {
        return false;
      }

      const result = hasErrors(
        this.bankRoutingRules,
        this.registration.payee.bank_routing_number
      );

      return !result;
    },

    stepThreeIsValid() {
      if (this.loading) {
        return false;
      }

      const result =
        hasErrors(this.employeeNameRules, this.employee.name) ||
        hasErrors(this.employeeEmailRules, this.employee.email) ||
        hasErrors(this.employeePhoneRules, this.employee.phone);

      return !result;
    },

    stepFourIsValid() {
      if (this.registration?.warehouses.length > 0) {
        return !this.registration.warehouses.some(w => !w.distributor_name);
      }

      return false;
    },

    getStepFourErrorMsg() {
      if (this.registration?.warehouses.length === 0) {
        return "There must be a least one theater";
      } else {
        return "All theaters must have a distributor";
      }
    },

    stepFiveIsValid() {
      return (
        this.employee.name && this.employee.name === this.employee.signature
      );
    },

    canSign() {
      return (
        this.stepOneIsValid() &&
        this.stepThreeIsValid() &&
        this.stepFourIsValid() &&
        this.stepFiveIsValid()
      );
    },

    signContract() {
      // update the contract record and recall sign

      this.sendingContractInfo = true;
      let data = this.getContractData();
      data.signed = true;

      api.updateContractData(this.regCode, data).then(() => {
        api.getContractVerification(this.regCode).then(() => {
          this.step = 6;
        });
      });
    },

    validateString(value) {
      if (!value?.trim()) {
        //str is null, undefined, or contains only spaces
      }
    },

    validateEmployeeName(value) {
      if (!value) return "Invalid name";
      if (value === this.registration.payee.payee_name)
        return "Cannot be the same as company name";
      return nameRegExp.test(value) || "Please use your full name";
    },

    validateEmployeeEmail(value) {
      return isValidEmail(value) || "Invalid email";
    },

    validateEmployeePhone(value) {
      return isValidPhone(value) || "Invalid phone number or format";
    },

    ValidateDCN(value) {
      const found = this.registration.warehouses.find(
        x =>
          x.external_customer_id === value &&
          x.subID !== this.currentTheater.subID
      );

      if (found) return "Warehouse already exists in list";

      if (value) return true;

      const dist_name = this.currentTheater.distributor_name;
      if (dist_name) {
        const x =
          VistarRegExp.test(dist_name) ||
          LibertyRegExp.test(dist_name) ||
          GoldMedalRegExp.test(dist_name);
        return !x || "Must supply a DCN for this distributor";
      } else {
        return true;
      }
    },

    removeTheater(item) {
      this.$refs.confirm
        .open("Delete", `Are you sure want to remove: ${item.name}? `, {
          color: "#C62828",
          width: 500
        })
        .then(confirm => {
          if (confirm) {
            this.registration.warehouses = this.registration.warehouses.filter(
              w => w.subID != item.subID
            );
            this.removedTheaters.push(item);
          }
        });
    },

    saveTheaterDialog() {
      if (this.isEditing) {
        const i = this.registration.warehouses.findIndex(
          w => w.subID == this.currentTheater.subID
        );
        let oldTheather = this.registration.warehouses[i];
        if (!deepEqual(this.currentTheater, oldTheather)) {
          this.editedTheaters.push(this.currentTheater);
          oldTheather.distributor_name = this.currentTheater.distributor_name;
          oldTheather.name = this.currentTheater.name.toUpperCase();
          oldTheather.city = this.currentTheater.city.toUpperCase();
          oldTheather.state = this.currentTheater.state.toUpperCase();
          oldTheather.external_customer_id = this.currentTheater.external_customer_id;
        }
      } else {
        this.currentTheater.subID = this.registration.warehouses.length + 1;
        this.currentTheater.name = this.currentTheater.name.toUpperCase();
        this.currentTheater.city = this.currentTheater.city.toUpperCase();
        this.currentTheater.state = this.currentTheater.state.toUpperCase();
        this.registration.warehouses.push(this.currentTheater);
      }
      this.isEditing = false;
      this.currentTheater = Object.assign({}, defaultTheater);
      this.theaterDialog = false;
    },

    editTheater(item) {
      this.currentTheater = Object.assign({}, item);
      this.isEditing = true;
      this.theaterDialog = true;
    },

    addTheater() {
      this.currentTheater = Object.assign({}, defaultTheater);
      this.isEditing = false;
      this.theaterDialog = true;
    },

    convertWarehouse(w) {
      return {
        name: w.distributor_name,
        dcn: w.external_customer_id,
        warehouse_name: w.name,
        city: w.city,
        state: w.state
      };
    },

    getContractData() {
      return {
        registration_code: this.regCode,
        email: this.employee.email,
        contract_info: this.getContractInfo(),
        other_info: {
          removed: this.removedTheaters,
          added: this.addedTheaters,
          updated: this.editedTheaters
        }
      };
    },

    sendContractInfo() {
      this.sendingContractInfo = true;
      let data = this.getContractData();

      const self = this;

      api.updateContractData(this.regCode, data).then(() => {
        api.getContract(self.regCode).then(response => {
          this.contractURL = response.data.contract_url;
        });

        this.sendingContractInfo = false;
        this.step = 5;
      });
    },

    getRowClass(item) {
      return item.distributor_name ? "" : "noDistributor";
    },

    dblclickRow(_, row) {
      this.editTheater(row.item);
    },

    getDistributorName(item) {
      return item.distributor_name || "UNKNOWN";
    },

    getPayeeAddress() {
      let result = `${this.registration.payee.house_number} ${this.registration.payee.street}`;

      if (this.registration.payee.street_2) {
        result = result + `, ${this.registration.payee.street_2}`;
      }

      return (
        result +
        `, ${this.registration.payee.city}, ${this.registration.payee.state}, ${this.registration.payee.zip}`
      );
    },

    getContractInfo() {
      return {
        registration_code: this.$route.params.code,
        payee_name: this.registration.payee.payee_name,
        payee_address: this.getPayeeAddress(),
        contact_name: this.employee.name,
        contact_email: this.employee.email,
        contact_phone: this.employee.phone,
        tax_id: this.registration.payee.tax_id,
        bank_name: this.registration.payee.bank_name,
        bank_routing_number: this.registration.payee.bank_routing_number,
        bank_account_number: this.registration.payee.bank_account_number,
        bank_account_holder_name: this.registration.payee
          .bank_account_holder_name,
        distributors: this.registration.warehouses.map(this.convertWarehouse)
      };
    },

    theaterValid() {
      return this.$refs.theaterForm?.validate();
    },

    validateRoutingNumber(value) {
      if (!value) {
        // Per JE, allow them to skip this step, but if they do supply something, it must be valid
        return true;
      }

      var routing = value.toString();

      //gotta be 9 digits
      var match = routing.match("^\\d{9}$");
      if (!match) {
        return false;
      }

      //The first two digits of the nine digit RTN must be in the ranges 00 through 12, 21 through 32,
      // 61 through 72, or 80.
      //https://en.wikipedia.org/wiki/Routing_transit_number
      const firstTwo = parseInt(routing.substring(0, 2));
      const firstTwoValid =
        (0 <= firstTwo && firstTwo <= 12) ||
        (21 <= firstTwo && firstTwo <= 32) ||
        (61 <= firstTwo && firstTwo <= 72) ||
        firstTwo === 80;
      if (!firstTwoValid) {
        return false;
      }

      //this is the checksum
      //http://www.siccolo.com/Articles/SQLScripts/how-to-create-sql-to-calculate-routing-check-digit.html
      const weights = [3, 7, 1];
      var sum = 0;
      for (var i = 0; i < 8; i++) {
        sum += parseInt(routing[i]) * weights[i % 3];
      }

      return (10 - (sum % 10)) % 10 === parseInt(routing[8]);
    },

    validateAccountNumber(value) {
      if (/^\d+$/.test(value)) {
        let i = parseInt(value, 10);
        if (i === 0) {
          return false;
        }

        if (value.length < 4 || value.length > 17) {
          return false;
        }

        return true;
      } else {
        return false;
      }
    },

    validateAccountHolderName(value) {
      if (!value) return "Invalid name";
      return nameRegExp.test(value) || "Please use full name";
    },

    getContractYear() {
      if (this.registration?.payee) {
        return this.registration.payee.contract_year;
      }

      return null;
    }
  },

  computed: {
    ...mapState("registration", [
      "loading",
      "notFound",
      "status",
      "registration"
    ])
  },

  watch: {
    status(newValue) {
      this.$router.push({
        name: newValue.status,
        params: { code: this.regCode, email: newValue?.email }
      });
    },

    notFound(newValue) {
      if (newValue) {
        this.$router.push({
          name: "Registration",
          params: { badCode: this.regCode }
        });
      }
    }
  }
};
</script>

<style>
.noDistributor {
  background-color: #ef5350;
}

.errorMsg {
  color: #ef5350 !important;
}
</style>
